html {
  min-width: 319px;
  background-color: #F6F7F9;
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
}

h1 {
  font-size: 38px;
  line-height: 45px;
  font-weight: 400;
  margin: 0 0 .67em;
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.00938em;
  margin: 0 0 10px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.register-link {
  text-decoration: underline !important;
  -webkit-text-decoration: underline !important;
}
