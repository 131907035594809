/* Required to style the adult-sailing-courses_part_7 section to make the content responsive */

.courseDatesContainer {
    display: flex !important;
    flex-direction: row;
    margin-top: 30px !important;
}

.courseDatesColumn {
    width: calc(50% - 50px);
    margin-right: 50px !important;
}

.courseHeadline {
    font-size: 18px !important;
    text-decoration: underline;
}

.courseInfo {
    line-height: 20px !important;
    margin: 0 !important;
}

.courseDateHeadline {
    font-weight: 500;
}

.courseDateInfo {
    line-height: 20px !important;
    padding-left: 10px !important;
    margin: 0 !important;
}

.courseDateInfo.last {
    margin-bottom: 20px !important;
}

@media (max-width: 950px) {
    .courseDatesContainer {
        flex-direction: column;
    }

    .courseDatesColumn {
        width: calc(100% - 50px);
    }
}


