.sun-editor {
    border: none;
    background-color: transparent;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
    background-color: transparent;
}

.sun-editor-editable {
    color: #000000;
    background-color: transparent;
    height: auto;
    padding: 0;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 500;
}

.sun-editor-editable h1,
.sun-editor-editable h1 * {
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 20px;
}

.sun-editor-editable h2,
.sun-editor-editable h2 *{
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 20px;
}

.sun-editor-editable h1,
.sun-editor-editable h2 {
    font-weight: 500;
}

.sun-editor-editable p,
.sun-editor-editable p *{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.00938em;
    margin: 0 0 10px;
}

.sun-editor-editable * {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 400;
}